@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
