:root {
    --orange: #ff7a00;
    --blue: #012fa6;
}

.mobile-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(20, 1fr);
    gap: 19px;
    position: relative;
}

.mobile-grid > * {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.mobile-img-1 {
    grid-column: 1/1;
    grid-row: 4/7;
    padding: 4px;
    position: absolute;
    top: -98%;
}
.mobile-img-2 {
    grid-column: 1/1;
    grid-row: 4/7;
}
.mobile-img-3 {
    grid-column: 2/2;
    grid-row: 4/7;
}

.mobile-img-4 {
    grid-column: 2/2;
    grid-row: 4/7;
    position: absolute;
    top: 60%;
}

.mobile-img-5 {
    grid-column: 1/1;
    grid-row: 7/11;
}

.mobile-img-6 {
    grid-column: 2/2;
    grid-row: 7/11;
}

.mobile-img-7 {
    grid-column: 1/1;
    grid-row: 7/11;
    position: absolute;
    top: 69%;
}

.mobile-img-8 {
    grid-column: 2/2;
    grid-row: 11/13;
}

.mobile-img-9 {
    grid-column: 2/2;
    grid-row: 13/15;
}

.mobile-img-10 {
    grid-column: 1/1;
    grid-row: 15/19;
    position: absolute;
    top: -75%;
}

.mobile-img-11 {
    grid-column: 1/1;
    grid-row: 15/19;
}

.mobile-img-12 {
    grid-column: 2/2;
    grid-row: 17/21;
}

.circle {
    position: absolute;
    border-radius: 1000px;
}

.mobile-circle-orange {
    --w-circle-orange: 160px;
    z-index: 0;
    grid-column: 1/1;
    grid-row: 4/7;
    left: 50%;
    top: 50%;
    height: var(--w-circle-orange);
    width: var(--w-circle-orange);
    background-color: var(--orange);
}

.mobile-circle-blue {
    --w-circle-blue: 70px;
    grid-column: 2/2;
    grid-row: 7/11;
    right: 0;
    top: 82%;
    width: var(--w-circle-blue);
    height: var(--w-circle-blue);
    background-color: var(--blue);
}

.mobile-triangle-orange {
    --w-triangle: 60px;
    border-bottom: calc(0.866 * var(--w-triangle)) solid var(--orange);
    border-left: calc(var(--w-triangle) / 2) solid transparent;
    border-right: calc(var(--w-triangle) / 2) solid transparent;
    border-top: 0;
    height: 0;
    width: 0;
    grid-column: 1/1;
    grid-row: 15/19;
    transform: rotate(100deg);
    position: absolute;
    left: 90%;
    top: 40%;
}

@media (min-width: 427px) {
    .mobile-circle-orange {
        --w-circle-orange: 180px;
    }
    .mobile-circle-blue {
        --w-circle-blue: 80px;
    }
    .mobile-triangle-orange {
        --w-triangle: 68px;
    }
}

.we-make-it-easy.mobile {
    grid-column: 1/1;
    grid-row: 19/21;
    display: grid;
    place-content: center;
    font-size: 30px;
}

.z-2 {
    z-index: 2;
}

.width-height-auto {
    width: auto;
    height: auto;
}

.aspect {
    aspect-ratio: 41/ 37;
}

.aspect-half {
    aspect-ratio: 1.78409091;
}
