:root {
  background-color: #f9fffb;
}

.App {
  width: 100vw;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: #f9fffb;
}

.mont800 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

.mont700 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.mont600 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.mont500 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.mont400 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

/*scrollbar*/

::-webkit-scrollbar {

  -webkit-appearance: none;

}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #ffffff; 
}

::-webkit-scrollbar-thumb {

  background: #5FDD7B; 
  border:1px solid #eee;
  height:100px;
  border-radius:5px;

}

::-webkit-scrollbar-thumb:hover {

background: #49AB5F; 

}

h1 {
  font-size: 3.3em;
}

h2 {
  font-size: 2.6em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.3em;
}

h5 {
  font-size: 1.16em;
}

h6 {
  font-size: 2.6em;
}

p {
  font-size: 1em;
}

@media (min-width: 100px) and (max-width: 834px) {

  h1 {
    font-size: 2em;
  }
  
  h2 {
    font-size: 1.8em;
  }
  
  h3 {
    font-size: 1.6em;
  }
  
  h4 {
    font-size: 1.3em;
  }
  
  h5 {
    font-size: 1.16em;
  }
  
  h6 {
    font-size: 1.8em;
  }

  p {
    font-size: 1em;
  }

}

