ul {
    display: block;
}

* {
    list-style: initial;
    text-align: start;
}

.container > * {
    margin-bottom: 16px;
}
