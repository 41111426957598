:root {
    --orange: #ff7a00;
    --blue: #012fa6;
}

.desktop-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 19px;
    position: relative;
}

.desktop-grid > * {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.desktop-img-1 {
    grid-column: 2/5;
}

.desktop-img-2 {
    grid-column: 5/10;
}

.desktop-img-3 {
    grid-column: 10/13;
}

.desktop-img-4 {
    grid-row: 1/1;
    grid-column: 10/13;
    position: absolute;
    top: 58%;
}

.desktop-img-5 {
    grid-column: 3/6;
}

.desktop-img-6 {
    grid-column: 6/9;
}

.desktop-img-7 {
    grid-row: 2/2;
    grid-column: 3/6;
    position: absolute;
    top: 58%;
    left: 60%;
}

.desktop-img-8 {
    grid-column: 1/8;
}

.desktop-img-9 {
    grid-column: 8/13;
}

.desktop-img-10 {
    grid-row: 3/3;
    grid-column: 1/5;
    position: absolute;
    top: 56%;
    left: 23%;
    max-width: 78%;
}

.desktop-img-11 {
    grid-column: 2/5;
}
.desktop-img-12 {
    grid-column: 5/8;
}

.circle {
    position: absolute;
    border-radius: 1000px;
}

.desktop-square-orange {
    --w-circle-orange: 180px;
    position: absolute;
    z-index: 0;
    grid-column: 2/5;
    grid-row: 1/1;
    left: 80%;
    top: 60%;
    height: var(--w-circle-orange);
    width: var(--w-circle-orange);
    background-color: var(--orange);
}

.desktop-circle-blue {
    --w-circle-blue: 130px;
    grid-column: 6/9;
    grid-row: 2/2;
    left: 68%;
    top: 80%;
    width: var(--w-circle-blue);
    height: var(--w-circle-blue);
    background-color: var(--blue);
}

.desktop-triangle-orange {
    --w-triangle: 70px;
    border-bottom: calc(0.866 * var(--w-triangle)) solid var(--orange);
    border-left: calc(var(--w-triangle) / 2) solid transparent;
    border-right: calc(var(--w-triangle) / 2) solid transparent;
    border-top: 0;
    height: 0;
    width: 0;
    grid-column: 2/5;
    grid-row: 4/4;
    transform: rotate(100deg);
    position: absolute;
    left: -25%;
    top: 62%;
}

.we-make-it-easy.desktop {
    grid-column: 8/13;
    display: grid;
    place-content: center;
    font-size: 60px;
}

@media (min-width: 1100px) {
    .desktop-square-orange {
        --w-circle-orange: 240px;
    }
    .desktop-circle-blue {
        --w-circle-blue: 170px;
    }
    .desktop-triangle-orange {
        --w-triangle: 90px;
    }
    .we-make-it-easy.desktop {
        font-size: 72px;
    }
}
@media (min-width: 1400px) {
    .desktop-square-orange {
        --w-circle-orange: 280px;
    }
    .desktop-circle-blue {
        --w-circle-blue: 200px;
    }
    .desktop-triangle-orange {
        --w-triangle: 105px;
    }
    .we-make-it-easy.desktop {
        font-size: 84px;
    }
}

.z-2 {
    z-index: 2;
}

.width-height-auto {
    width: auto;
    height: auto;
}

.aspect {
    aspect-ratio: 41/ 37;
}

.aspect-half {
    aspect-ratio: 1.78409091;
}
