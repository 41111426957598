ul {

  list-style: none;
  display: flex;
  justify-content: space-around;

}

ul li a{

  font-family: "Montserrat", sans-serif;
  color: #000000;
  opacity: 50%;
  font-weight: 400;
  font-size: 1em;
  padding: 0 20px;

}

ul li a:hover{
  
  color: #49AB5F;
  opacity: 100%;

}


.active a{
  
  color: #49AB5F;
  font-weight: 600;
  opacity: 100%;

}


/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 28px;
  height: 24px;
  left: 45vw;
  right: 45vw;
  margin-top: 1.2em;
  margin-left: auto;
  margin-right: auto;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #4AAB5F;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #51BF69;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 16px;
  width: 16px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #FAFFFD;
  width: 100vw;
  height: 100vh;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}